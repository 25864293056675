
import OSS from 'ali-oss';
import * as echarts from 'echarts';
const DEFAULT_LINE_STYLE = {
    type: 'line',
    smooth: true,
    lineStyle: {
        width: 3
    },
    showSymbol: false,
    areaStyle: {
        opacity: 0.5
    }
};
const DEFAULT_LEGEND_STYLE = {
    icon: 'circle',
    right: '50px'
};
const DEFAULT_GRID_SYTLE = {
    left: '50px',
    right: '50px'
};
const DEFAULT_TITLE_STYLE = {
    textStyle: {
        fontSize: '12px',
        color: '#3a7dbc'
    },
    left: '2%'
};
const LINE_SYTLE_SHOW_SYMBOL = {
    areaStyle: {
        opacity: 0
    },
    showSymbol: true
};
const DEFAULT_TOOLTIP_STYLE = {
    trigger: 'axis',
    axisPointer: {
        type: 'cross',
        label: {
            backgroundColor: '#6a7985'
        }
    }
};
export default {
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            resetOssNum: 0,
            activeName: '1',
            download0: false,
            download1: false,
            download2: false,
            download3: false,
            download4: false,
            download5: false,
            download9901: false,
            download9902: false,
            download9903: false,
            download9904: false,
            loadingChart1: false,
            loadingChart2: false,
            loadingChart3: false,
            highRiskShippingSiteLoading: false,
            highRiskReceivingSiteLoading: false,
            preCaseStatisticsDayLoading: false,
            caseDataStatisticsDayLoading: false,
            caseDataStatisticsMonthLoading: false,
            prevCaseBarChartOfWeekLoading: false,
            caseDataStatisticsLoading: false,
            prevCaseBarChartLoading: false,
            navForm: {
                startTime: '',
                endTime: ''
            },
            tableForm: {
                condition: {
                    startTime: null,
                    endTime: null,
                    riskName: ''
                },
                pageNo: 1,
                pageSize: 5
            },
            caseRiskDetailsData: {
                data: {
                    list: []
                }
            },
            pickerOptions: {
                disabledDate(time) {
                    const one = 24 * 3600 * 1000; //  一个月的时间间隔（毫秒数）
                    return time.getTime() > new Date().getTime() - one;
                }
            },
            dayReportCharts: {
                loading: false,
                caseLineChart: null,
                prevCaseLineChart: null
            },
            weekReportCharts: {
                loading: false,
                caseBarChart: null,
                prevCaseBarChart: null
            },
            monthReportCharts: {
                loading: false,
                caseBarChart: null,
                prevCaseBarChart: null
            },
            caseInflowTrendForecastData: {},
            compensationRateMonitoringData: {},
            claimAmountStatisticsData: {},
            highRiskShippingSiteData: {
                data: { list: [] }
            },
            highRiskReceivingSiteData: {
                data: { list: [] }
            },
            riskTypeTitleData: [],
            riskTypeDataAll: {},
            caseClosingTimeAnalysisData: [
                { name: '--', data: '--' },
                { name: '--', data: '--' },
                { name: '--', data: '--' },
                { name: '--', data: '--' },
                { name: '--', data: '--' },
                { name: '--', data: '--' }
            ],
            preCaseTimeAnalysisData: [
                { name: '--', data: '--' },
                { name: '--', data: '--' }
            ],
            pickerOptions1: {},
            pickerOptions2: {},
            caseDataStatisticsDayShow: true,
            preCaseDataStatisticsDayShow: true,
            caseDataStatisticsMonthShow: true,
            prevCaseBarChartOfWeekShow: true,
            caseDataStatisticsShow: true,
            prevCaseBarChartShow: true
        };
    },
    mounted() {
        this.initOSSClient();
        let today = new Date();
        today.setDate(today.getDate() - 1);
        this.navForm.startTime = '2023-09-01';
        this.navForm.endTime = this.formatDate(today);
        // this.navForm.endTime = '2023-09-14';

        this.queryData();

        window.addEventListener('resize', () => {
            this.chartsResize();
        });

        this.pickerOptions1.disabledDate = (time) => {
            // 昨天
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            return time.getTime() > yesterday || time.getTime() < this.getTimeStamp('2023-09-01');
        };
        this.pickerOptions2.disabledDate = (time) => {
            // 昨天
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            return time.getTime() > yesterday || time.getTime() < this.getTimeStamp('2023-09-01');
        };
    },
    methods: {
        startTimeChange() {
            this.pickerOptions2.disabledDate = (time) => {
                // 昨天
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                return time.getTime() > yesterday || time.getTime() < this.getTimeStamp(this.navForm.startTime);
            };
        },
        endTimeChange() {
            this.pickerOptions1.disabledDate = (time) => {
                // 昨天
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                return time.getTime() > this.getTimeStamp(this.navForm.endTime) || time.getTime() < this.getTimeStamp('2023-09-01');
            };
        },
        handleClick(tab, event) {
            setTimeout(() => {
                this.tabClickQuery();
            }, 100);
        },
        chartsResize() {
            this.largeStyle = window.innerHeight > 714;
            setTimeout(() => {
                this.caseLineChartOfDay && this.caseLineChartOfDay.resize();
                this.myChartId1 && this.myChartId1.resize();
                this.myChartId2 && this.myChartId2.resize();
                this.myChartId3 && this.myChartId3.resize();
                this.myChartId4 && this.myChartId4.resize();
                this.myChartId5 && this.myChartId5.resize();
                this.myChartId6 && this.myChartId6.resize();
                this.myChartId7 && this.myChartId7.resize();
                this.myChartId8 && this.myChartId8.resize();
            }, 200);
        },
        extractNumber(str) {
            const regex = /\d+/; // 匹配一个或多个数字
            const match = str.match(regex);
            return match ? match[0] : null;
        },
        renderCaseBarChartOfWeek(data, title, chart, maxWeekNum) {
            const value1 = data.value1;
            const value2 = data.value2;
            const color = ['#8375C4', '#6AC0FD', '#FAB533', '#5CAF33', '#C194F8', '#9BECFB'];
            const xAxis = { type: 'category', data: data.xAxisData };
            const yAxis = {
                type: 'value'
            };

            let legends = [];
            const series = data.tmpSeries;

            for (let i = 0; i < maxWeekNum; i++) {
                legends.push(`第${i + 1}周`);
            }

            const option = {
                color: color,
                xAxis: [xAxis],
                yAxis: [yAxis],
                title: { ...DEFAULT_TITLE_STYLE, text: title },
                tooltip: {
                    ...DEFAULT_TOOLTIP_STYLE,
                    formatter: (params) => {
                        var trStr = ``;
                        // console.log(params);
                        switch (maxWeekNum) {
                            case 5:
                                var html = `
                            <div>
                                <span>${params[0].axisValue}</span>
                                <hr>
                                <table>
                                    <tr height="20px"><td width="20px"></td><td width="50px"></td><td width="80px" style="text-align:center;">流入案件量</td><td width="30px"></td><td width="80px" style="text-align:center;">接案量</td></tr>
                                    <tr height="20px"><td width="20px"><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[0]}"></span></td><td>第1周</td><td style="text-align:center;">${params[0].data}</td><td width="30px"></td><td style="text-align:center;">${params[5].data}</td></tr>
                                    <tr height="20px"><td width="20px"><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[1]}"></span></td><td>第2周</td><td style="text-align:center;">${params[1].data}</td><td width="30px"></td><td style="text-align:center;">${params[6].data}</td></tr>
                                    <tr height="20px"><td width="20px"><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[2]}"></span></td><td>第3周</td><td style="text-align:center;">${params[2].data}</td><td width="30px"></td><td style="text-align:center;">${params[7].data}</td></tr>
                                    <tr height="20px"><td width="20px"><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[3]}"></span></td><td>第4周</td><td style="text-align:center;">${params[3].data}</td><td width="30px"></td><td style="text-align:center;">${params[8].data}</td></tr>
                                    <tr height="20px"><td width="20px"><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[4]}"></span></td><td>第5周</td><td style="text-align:center;">${params[4].data}</td><td width="30px"></td><td style="text-align:center;">${params[9].data}</td></tr>
                                </table>
                            </div>
                        `;
                                break;
                            case 6:
                                var html = `
                            <div>
                                <span>${params[0].axisValue}</span>
                                <hr>
                                <table>
                                    <tr height="20px"><td width="20px"></td><td width="50px"></td><td width="80px" style="text-align:center;">流入案件量</td><td width="30px"></td><td width="80px" style="text-align:center;">接案量</td></tr>
                                    <tr height="20px"><td width="20px"><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[0]}"></span></td><td>第1周</td><td style="text-align:center;">${params[0].data}</td><td width="30px"></td><td style="text-align:center;">${params[6].data}</td></tr>
                                    <tr height="20px"><td width="20px"><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[1]}"></span></td><td>第2周</td><td style="text-align:center;">${params[1].data}</td><td width="30px"></td><td style="text-align:center;">${params[7].data}</td></tr>
                                    <tr height="20px"><td width="20px"><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[2]}"></span></td><td>第3周</td><td style="text-align:center;">${params[2].data}</td><td width="30px"></td><td style="text-align:center;">${params[8].data}</td></tr>
                                    <tr height="20px"><td width="20px"><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[3]}"></span></td><td>第4周</td><td style="text-align:center;">${params[3].data}</td><td width="30px"></td><td style="text-align:center;">${params[9].data}</td></tr>
                                    <tr height="20px"><td width="20px"><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[4]}"></span></td><td>第5周</td><td style="text-align:center;">${params[4].data}</td><td width="30px"></td><td style="text-align:center;">${params[10].data}</td></tr>
                                    <tr height="20px"><td width="20px"><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[5]}"></span></td><td>第6周</td><td style="text-align:center;">${params[5].data}</td><td width="30px"></td><td style="text-align:center;">${params[11].data}</td></tr>
                                </table>
                            </div>
                        `;
                                break;

                            default:
                                params.forEach((item, index) => {
                                    if (index < maxWeekNum) {
                                        trStr += `
                                <tr height="20px"><td width="20px"><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${color[index]}"></span></td><td>第${item.seriesIndex + 1}周</td><td style="text-align:center;">${
                                            params[index].data || 0
                                        }</td><td width="30px"></td><td style="text-align:center;">${params[index + maxWeekNum].data || 0}</td></tr>
                                `;
                                    }
                                });
                                var html =
                                    `
                            <div>
                                <span>${params[0].axisValue}</span>
                                <hr>
                                <table>
                                    <tr height="20px"><td width="20px"></td><td width="50px"></td><td width="80px" style="text-align:center;">流入案件量</td><td width="30px"></td><td width="80px" style="text-align:center;">接案量</td></tr>
                                    ` +
                                    trStr +
                                    `
                                </table>
                            </div>
                        `;
                                break;
                        }
                        return html;
                    }
                },
                grid: DEFAULT_GRID_SYTLE,
                legend: {
                    data: legends,
                    borderRadius: 20
                },
                series: series
            };
            chart.clear();
            chart.setOption(option);
        },
        renderCaseBarChartOfMonth(data, title, chart) {
            const months = data.months;
            const value1 = data.value1;
            const value2 = data.value2;
            const color = ['#8375C4', '#6AC0FD'];
            const xAxis = { type: 'category' };
            xAxis.data = months;
            const yAxis = {
                type: 'value'
            };
            const legends = ['流入案件量', '接案量'];
            const series = [
                {
                    name: legends[0],
                    data: value1,
                    type: 'bar',
                    stack: 'one',
                    barMaxWidth: '40px'
                },
                {
                    name: legends[1],
                    data: value2,
                    type: 'bar',
                    stack: 'two',
                    barMaxWidth: '40px'
                }
            ];

            const option = {
                color: color,
                xAxis: [xAxis],
                yAxis: [yAxis],
                title: { ...DEFAULT_TITLE_STYLE, text: title },
                tooltip: {
                    ...DEFAULT_TOOLTIP_STYLE
                    // formatter: (params) => {
                    // }
                },
                grid: DEFAULT_GRID_SYTLE,
                legend: {
                    data: legends,
                    borderRadius: 20
                },
                series: series
            };
            chart.clear();
            chart.setOption(option);
        },
        renderCaseLineChartOfDay(data, seriesName, title, chart) {
            const days = data.days;
            const inflowCounts = data.inflowCounts;
            const receiveCounts = data.receiveCounts;
            const endCounts = data.endCounts;
            const xAxis = { type: 'category' };

            xAxis.data = days;
            const yAxis = {
                type: 'value'
            };
            //const seriesName = ['案件流入量', '接案量', '结案量'];
            const series = [
                {
                    ...DEFAULT_LINE_STYLE,
                    name: seriesName[0],
                    data: inflowCounts
                },
                {
                    ...DEFAULT_LINE_STYLE,
                    name: seriesName[1],
                    data: receiveCounts
                },
                {
                    ...DEFAULT_LINE_STYLE,
                    name: seriesName[2],
                    data: endCounts
                }
            ];

            const option = {
                title: { ...DEFAULT_TITLE_STYLE, text: title },
                tooltip: DEFAULT_TOOLTIP_STYLE,
                grid: DEFAULT_GRID_SYTLE,
                color: ['#2d3087', '#008000', '#646DC8'],
                legend: { ...DEFAULT_LEGEND_STYLE, data: seriesName },
                xAxis: [xAxis],
                yAxis: [yAxis],
                dataZoom: [
                    {
                        type: 'slider',
                        height: '15px',
                        xAxisIndex: 0,
                        filterMode: 'none'
                    }
                ],
                series: series
            };
            chart.clear();
            chart.setOption(option);
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        getTimeStamp(timeStr) {
            return new Date(timeStr.split('-')[0], timeStr.split('-')[1] - 1, timeStr.split('-')[2]).getTime();
        },
        queryData() {
            let tmpForm = JSON.parse(JSON.stringify(this.navForm));
            this.tableForm.condition = {
                startTime: tmpForm.startTime,
                endTime: tmpForm.endTime
            };
            this.tabClickQuery();
            // 结案时效分析
            this.getCaseClosingTimeAnalysis();
            // 预报案时效分析
            this.getPreCaseTimeAnalysis();
            // 案件流入趋势预测
            this.getCaseInflowTrendForecast();
            // 赔付率监控
            this.getCompensationRateMonitoring();
            // 索赔金额统计
            this.getMonthlyClaimAmountStatistics();
            // 高风险发货站点TOP10
            this.getHighRiskShippingSiteTop10();
            // 高风险收货站点TOP10
            this.getHighRiskReceivingSiteTop10();
            // 风险作业量统计title
            this.getRiskTypeTitle();
            // 验真失败案件详情
            this.getCaseRiskDetails();
        },
        tabClickQuery() {
            let tmpForm = JSON.parse(JSON.stringify(this.navForm));
            this.tableForm.condition = {
                startTime: tmpForm.startTime,
                endTime: tmpForm.endTime
            };
            switch (this.activeName) {
                case '1':
                    this.getCaseDataStatistics();
                    this.getCaseDataStatistics2();
                    this.getCaseDataStatistics3();
                    this.getCaseDataStatistics4();
                    this.getCaseDataStatistics5();
                    break;
                case '2':
                    // 案件数据统计分析(日)
                    this.getCaseDataStatisticsDay();
                    // 预报案案件数据统计分析(日)
                    this.getPreCaseStatisticsDay();

                    break;
                case '3':
                    // 案件数据统计分析(月) - 包含了周
                    this.getCaseDataStatisticsMonth('week');
                    // 预报案案件数据统计分析(月) - 包含了周
                    this.getPreCaseStatisticsMonth('week');

                    break;
                case '4':
                    // 案件数据统计分析(月) - 包含了周
                    this.getCaseDataStatisticsMonth('month');
                    // 预报案案件数据统计分析(月) - 包含了周
                    this.getPreCaseStatisticsMonth('month');

                    break;

                default:
                    break;
            }
        },
        downloadXlsx(type) {
            let instanceUrl = '';
            let instanceForm = this.navForm;
            this.fileFLag = false;
            switch (type) {
                case 1:
                    // 案件流入趋势预测
                    instanceUrl = '/tpa/api/dashboard/fixed/caseInflowTrendForecast/excel';
                    break;
                case 2:
                    // 赔付率监控
                    instanceUrl = '/tpa/api/dashboard/fixed/compensationRateMonitoring/excel';

                    break;
                case 3:
                    // 高风险站点统计
                    instanceUrl = '/tpa/api/dashboard/fixed/highRiskSiteDashboard/excel';

                    break;
                case 4:
                    // 风险点案件详情
                    instanceUrl = '/tpa/api/dashboard/fixed/riskPointCaseDetails/excel';

                    break;
                case 5:
                    // 风险点案件详情
                    instanceUrl = '/tpa/api/dashboard/fixed/riskPointCaseDetails/excel';
                    this.tableForm.condition.riskName = '酒品溯源失败';
                    instanceForm = this.tableForm.condition;

                    break;
                case 9901:
                    // 案件数据统计分析(总)
                    instanceUrl = '/tpa/api/dashboard/fixed/caseDataStatistics/excel';
                    break;
                case 9902:
                    // 案件数据统计分析(其它) 日
                    instanceUrl = '/tpa/api/dashboard/fixed/case/excel';
                    instanceForm.queryType = 'daily';
                    break;
                case 9903:
                    // 案件数据统计分析(其它) 周
                    instanceUrl = '/tpa/api/dashboard/fixed/case/excel';
                    instanceForm.queryType = 'weekly';
                    break;
                case 9904:
                    // 案件数据统计分析(其它) 月
                    instanceUrl = '/tpa/api/dashboard/fixed/case/excel';
                    instanceForm.queryType = 'monthly';
                    break;

                default:
                    break;
            }
            this['download' + type] = true;
            this.instance.post(instanceUrl, instanceForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.downloadFile(data.data, 'download' + type);
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        // 初始化OSS
        initOSSClient() {
            this.tmpOSSClient = null;
            this.resetOssNum++;
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.tmpOSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！');

                        if (this.resetOssNum < 20) {
                            setTimeout(() => {
                                this.initOSSClient();
                            }, 1000);
                        } else {
                            console.log('oss初始化超过20s，失败!');
                        }
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        downloadFile(fileKey, btnLoading) {
            if (this.tmpOSSClient) {
                this.isExistObject(fileKey);
                if (this.fileTime > 200) {
                    this.$message.error('文件下载失败，请稍后重试！');
                    return;
                }

                if (!this.fileFLag) {
                    setTimeout(() => {
                        this.fileTime++;
                        this.downloadFile(fileKey, btnLoading);
                    }, 3000);
                } else {
                    this.fileFLag = false;
                    this.fileTime = 0;
                    let fileUrl = this.tmpOSSClient.signatureUrl(fileKey);
                    this[btnLoading] = false;
                    location.href = fileUrl;
                }
            }
        },
        async isExistObject(name, options = {}) {
            try {
                await this.tmpOSSClient.head(name, options);
                this.fileFLag = true;
            } catch (error) {
                if (error.code === 'NoSuchKey') {
                    this.fileFLag = false;
                }
            }
        },
        handleCurrentChange(val) {
            this.monthlyClaimForm.pageNo = val;
            this.getMonthlyClaimData();
        },
        handleSizeChange(val) {
            this.monthlyClaimForm.pageSize = val;
            this.getMonthlyClaimData();
        },
        getCaseDataStatisticsDay() {
            this.caseDataStatisticsDayLoading = true;
            this.instance.post('/tpa/api/dashboard/fixed/caseDataStatisticsDay', this.navForm).then((res) => {
                this.caseDataStatisticsDayLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    if (data.data.data && data.data.data.list && data.data.data.list.length > 0) {
                        this.caseDataStatisticsDayShow = true;
                        let response = data.data;
                        let chartData = {
                            days: [],
                            inflowCounts: [],
                            receiveCounts: [],
                            endCounts: []
                        };
                        let seriesName = ['案件流入量', '接案量', '结案量'];
                        let chartTitle = response.title || '';

                        if (response && response.data && response.data.list) {
                            response.data.list.forEach((item, index) => {
                                chartData.days.push(item.date);
                                chartData.inflowCounts.push(item.caseInCount || 0);
                                chartData.receiveCounts.push(item.caseTakeOverCount || 0);
                                chartData.endCounts.push(item.caseClosingCount || 0);
                            });
                        }

                        let chartDom1 = document.getElementById('caseLineChartOfDay');
                        if (!chartDom1) {
                            return;
                        }
                        this.dayReportCharts.caseLineChart = echarts.init(chartDom1);
                        this.renderCaseLineChartOfDay(chartData, seriesName, chartTitle, this.dayReportCharts.caseLineChart);
                        this.chartsResize();
                    } else {
                        this.caseDataStatisticsDayShow = false;
                    }
                }
            });
        },
        getPreCaseStatisticsDay() {
            this.preCaseStatisticsDayLoading = true;
            this.instance.post('/tpa/api/dashboard/fixed/preCaseStatisticsDay', this.navForm).then((res) => {
                this.preCaseStatisticsDayLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    if (data.data.data && data.data.data.list && data.data.data.list.length > 0) {
                        this.preCaseDataStatisticsDayShow = true;
                    } else {
                        this.preCaseDataStatisticsDayShow = false;
                    }
                    let response = data.data;
                    let chartData = {
                        days: [],
                        inflowCounts: [],
                        receiveCounts: [],
                        endCounts: []
                    };
                    let seriesName = ['案件流入量', '接案量', '完成量'];
                    let chartTitle = response.title || '';

                    if (response && response.data && response.data.list) {
                        response.data.list.forEach((item, index) => {
                            chartData.days.push(item.date);
                            chartData.inflowCounts.push(item.caseInCount || 0);
                            chartData.receiveCounts.push(item.caseTakeOverCount || 0);
                            chartData.endCounts.push(item.caseClosingCount || 0);
                        });
                    }

                    let chartDom2 = document.getElementById('prevCaseLineChartOfDay');
                    if (!chartDom2) {
                        return;
                    }
                    this.dayReportCharts.prevCaseLineChart = echarts.init(chartDom2);

                    this.renderCaseLineChartOfDay(chartData, seriesName, chartTitle, this.dayReportCharts.prevCaseLineChart);
                    this.chartsResize();
                }
            });
        },
        getCaseDataStatisticsMonth(chartDataType) {
            if (chartDataType == 'week') {
                this.caseDataStatisticsMonthLoading = true;
            } else {
                this.caseDataStatisticsLoading = true;
            }
            this.instance.post('/tpa/api/dashboard/fixed/caseDataStatisticsMonth', this.navForm).then((res) => {
                if (chartDataType == 'week') {
                    this.caseDataStatisticsMonthLoading = false;
                } else {
                    this.caseDataStatisticsLoading = false;
                }
                let data = res.data;
                if (data.code == 200) {
                    let response = data.data;
                    if (chartDataType == 'week') {
                        if (response && response.data && response.data.list && response.data.list.length > 0) {
                            this.caseDataStatisticsMonthShow = true;
                            // start
                            let chartData = this.handleWeekData(response);
                            // end
                            setTimeout(() => {
                                let chartDom1 = document.getElementById('caseBarChartOfWeek');
                                if (!chartDom1) {
                                    return;
                                }
                                this.weekReportCharts.caseBarChart = echarts.init(chartDom1);
                                this.renderCaseBarChartOfWeek(chartData, response.title, this.weekReportCharts.caseBarChart, chartData.maxWeekNum);
                                this.chartsResize();
                            }, 100);
                        } else {
                            this.caseDataStatisticsMonthShow = false;
                        }
                    } else {
                        if (response && response.data && response.data.list && response.data.list.length > 0) {
                            this.caseDataStatisticsShow = true;
                            // month
                            // start
                            let chartData = this.handleMonthData(response);
                            // end

                            setTimeout(() => {
                                let chartDom1 = document.getElementById('caseBarChartOfMonth');
                                if (!chartDom1) {
                                    return;
                                }
                                this.monthReportCharts.caseBarChart = echarts.init(chartDom1);
                                this.renderCaseBarChartOfMonth(
                                    {
                                        months: chartData.xAxisData,
                                        value1: chartData.value1,
                                        value2: chartData.value2
                                    },
                                    response.title,
                                    this.monthReportCharts.caseBarChart
                                );
                                this.chartsResize();
                            }, 100);
                        } else {
                            this.caseDataStatisticsShow = false;
                        }
                    }
                }
            });
        },
        getPreCaseStatisticsMonth(chartDataType) {
            if (chartDataType == 'week') {
                this.prevCaseBarChartOfWeekLoading = true;
            } else {
                this.prevCaseBarChartLoading = true;
            }
            this.instance.post('/tpa/api/dashboard/fixed/preCaseStatisticsMonth', this.navForm).then((res) => {
                if (chartDataType == 'week') {
                    this.prevCaseBarChartOfWeekLoading = false;
                } else {
                    this.prevCaseBarChartLoading = false;
                }
                let data = res.data;
                if (data.code == 200) {
                    let response = data.data;
                    if (chartDataType == 'week') {
                        if (response.data && response.data.list && response.data.list.length > 0) {
                            this.prevCaseBarChartOfWeekShow = true;
                            // start
                            let chartData = this.handleWeekData(response);
                            // end

                            setTimeout(() => {
                                let chartDom2 = document.getElementById('prevCaseBarChartOfWeek');
                                if (!chartDom2) {
                                    return;
                                }
                                this.weekReportCharts.prevCaseBarChart = echarts.init(chartDom2);
                                this.renderCaseBarChartOfWeek(chartData, response.title, this.weekReportCharts.prevCaseBarChart, chartData.maxWeekNum);
                            }, 100);
                        } else {
                            this.prevCaseBarChartOfWeekShow = false;
                        }
                    } else {
                        if (response.data && response.data.list && response.data.list.length > 0) {
                            this.prevCaseBarChartShow = true;
                            // month
                            // start
                            let chartData = this.handleMonthData(response);
                            // end

                            setTimeout(() => {
                                let chartDom2 = document.getElementById('prevCaseBarChartOfMonth');
                                if (!chartDom2) {
                                    return;
                                }
                                this.monthReportCharts.prevCaseBarChart = echarts.init(chartDom2);

                                this.renderCaseBarChartOfMonth(
                                    {
                                        months: chartData.xAxisData,
                                        value1: chartData.value1,
                                        value2: chartData.value2
                                    },
                                    response.title,
                                    this.monthReportCharts.prevCaseBarChart
                                );
                            }, 100);
                        } else {
                            this.prevCaseBarChartShow = false;
                        }
                    }
                }
            });
        },
        handleWeekData(response) {
            let xAxisData = [];
            let weekSeriesObj = {};
            let tmpSeries = [];
            let tmpSeriesItem1 = [];
            let tmpSeriesItem2 = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let value1 = [];
            let value2 = [];
            let seriesData3 = [];
            let monthWeekData = [];
            let maxWeekNum = 'null';
            let hoverStr = '';
            if (response && response.columns) {
                response.columns.forEach((forItem, forIndex) => {
                    if (forItem.title.indexOf('月') == -1) {
                        hoverStr = forItem.title;
                    }
                });
            }
            if (response.data && response.data.list) {
                response.data.list.forEach((res) => {
                    xAxisData.push(res.date);
                    weekSeriesObj[res.date] = {};
                    if (res.weekData) {
                        // 找出每月最后一周
                        let tmpWeekData = [];
                        res.weekData.forEach((itemData) => {
                            tmpWeekData.push(Number(itemData.week));
                        });

                        weekSeriesObj[res.date].tmpWeekData = tmpWeekData;
                        weekSeriesObj[res.date].weekMax = Math.max(...tmpWeekData);
                        weekSeriesObj[res.date].weekData = res.weekData;
                    }
                });

                // 周，最终处理数据
                if (response.data.list.length > 0) {
                    // 找出数据中最大周数字
                    for (const key in weekSeriesObj) {
                        monthWeekData.push(weekSeriesObj[key].weekMax);
                    }
                    maxWeekNum = Math.max(...monthWeekData);

                    // 以最大的周为准，补全每周数据
                    for (const key in weekSeriesObj) {
                        seriesData1 = [];
                        seriesData2 = [];
                        for (let index = 0; index < maxWeekNum; index++) {
                            if (weekSeriesObj[key].tmpWeekData.indexOf(index + 1) != -1) {
                                weekSeriesObj[key].weekData.forEach((weekItem) => {
                                    if (Number(weekItem.week) == index + 1) {
                                        seriesData1.push(weekItem.caseInCount || 0);
                                        seriesData2.push(weekItem.caseTakeOverCount || 0);
                                    }
                                });
                            } else {
                                seriesData1.push(0);
                                seriesData2.push(0);
                            }
                            weekSeriesObj[key].seriesData1 = seriesData1;
                            weekSeriesObj[key].seriesData2 = seriesData2;
                        }
                    }
                    // 处理数据 end

                    // 组合图表数据 - tmpSeries
                    for (let index = 0; index < maxWeekNum; index++) {
                        // 流入量
                        tmpSeriesItem1.push({
                            name: `第${index + 1}周`,
                            type: 'bar',
                            barMaxWidth: '28px',
                            stack: 'seriesData1',
                            data: []
                        });
                        // 接案量
                        tmpSeriesItem2.push({
                            name: `第${index + 1}周`,
                            type: 'bar',
                            barMaxWidth: '28px',
                            stack: 'seriesData2',
                            data: []
                        });
                    }
                    for (const key in weekSeriesObj) {
                        weekSeriesObj[key].seriesData1.forEach((dataItem, dataIndex) => {
                            tmpSeriesItem1.forEach((tmpSeriesItem, tmpSeriesIndex) => {
                                if (tmpSeriesIndex == dataIndex) {
                                    tmpSeriesItem1[tmpSeriesIndex].data.push(dataItem);
                                }
                            });
                        });
                        weekSeriesObj[key].seriesData2.forEach((dataItem, dataIndex) => {
                            tmpSeriesItem2.forEach((tmpSeriesItem, tmpSeriesIndex) => {
                                if (tmpSeriesIndex == dataIndex) {
                                    tmpSeriesItem2[tmpSeriesIndex].data.push(dataItem);
                                }
                            });
                        });
                    }
                    value1 = tmpSeriesItem1;
                    value2 = tmpSeriesItem2;
                    tmpSeries = tmpSeriesItem1.concat(tmpSeriesItem2);
                } else {
                    tmpSeries = [
                        {
                            // name: '接案量',
                            type: 'bar',
                            barMaxWidth: '28px',
                            emphasis: {
                                focus: 'series'
                            },
                            data: seriesData1
                        }
                    ];
                }
            }

            return {
                xAxisData: xAxisData,
                maxWeekNum: maxWeekNum,
                value1: value1,
                value2: value2,
                tmpSeries: tmpSeries
            };
        },
        handleMonthData(response) {
            let xAxisData = [];
            let value1 = [];
            let value2 = [];
            if (response && response.data && response.data.list) {
                response.data.list.forEach((res) => {
                    xAxisData.push(res.date);
                    value1.push(res.caseInCount || 0);
                    value2.push(res.caseTakeOverCount || 0);
                });
            }

            return {
                xAxisData: xAxisData,
                value1: value1,
                value2: value2
            };
        },
        getCaseClosingTimeAnalysis() {
            this.instance.post('/tpa/api/dashboard/fixed/caseClosingTimeAnalysis', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.caseClosingTimeAnalysisData = data.data;
                }
            });
        },
        getCaseInflowTrendForecast() {
            this.instance.post('/tpa/api/fixed/dashboard/caseInflowTrendForecast').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.caseInflowTrendForecastData = data.data;
                    this.setChart1(this.caseInflowTrendForecastData);
                }
            });
        },
        getCompensationRateMonitoring() {
            this.instance.post('/tpa/api/dashboard/fixed/compensationRateMonitoring', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.compensationRateMonitoringData = data.data;
                    this.setChart2(this.compensationRateMonitoringData);
                }
            });
        },
        getMonthlyClaimAmountStatistics() {
            this.instance.post('/tpa/api/dashboard/fixed/monthlyClaimAmountStatistics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.claimAmountStatisticsData = data.data;
                    this.setChart3(this.claimAmountStatisticsData);
                }
            });
        },
        getHighRiskShippingSiteTop10() {
            this.highRiskShippingSiteData = {
                data: { list: [] }
            };
            this.highRiskShippingSiteLoading = true;
            this.instance.post('/tpa/api/fixed/dashboard/highRiskShippingSiteTop10', this.navForm).then((res) => {
                this.highRiskShippingSiteLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.highRiskShippingSiteData = data.data;
                }
            });
        },
        getHighRiskReceivingSiteTop10() {
            this.highRiskReceivingSiteData = {
                data: { list: [] }
            };
            this.highRiskReceivingSiteLoading = true;
            this.instance.post('/tpa/api/fixed/dashboard/highRiskReceivingSiteTop10', this.navForm).then((res) => {
                this.highRiskReceivingSiteLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.highRiskReceivingSiteData = data.data;
                }
            });
        },
        getRiskTypeTitle() {
            this.instance.post('/tpa/api/fixed/dashboard/riskTypeTitle', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.riskTypeTitleData = data.data;
                    this.riskTypeTitleData.forEach((item, index) => {
                        this.getImageRecognitionRisk(item, index);
                    });
                }
            });
        },
        getCaseRiskDetails() {
            this.tableForm.condition.riskName = '酒品溯源失败';
            this.instance.post('/tpa/api/fixed/dashboard/caseRiskDetails', this.tableForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.caseRiskDetailsData = data.data;
                    this.tableForm.pageTotal = data.data.data.total;
                }
            });
        },
        handleCurrentChange(val) {
            this.tableForm.pageNo = val;
            this.getCaseRiskDetails();
        },
        handleSizeChange(val) {
            this.tableForm.pageSize = val;
            this.getCaseRiskDetails();
        },
        getImageRecognitionRisk(riskStr, index) {
            let riskForm = JSON.parse(JSON.stringify(this.navForm));
            riskForm.riskName = riskStr;
            this.instance.post('/tpa/api/fixed/dashboard/imageRecognitionRisk', riskForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.riskTypeDataAll['riskTypeData' + index] = data.data;
                    this.setRiskChart(this.riskTypeDataAll['riskTypeData' + index], index);
                }
            });
        },
        setChart1(response) {
            let chartDom = document.getElementById('chartId1');
            if (!chartDom) {
                return;
            }
            this.myChartId1 = echarts.init(chartDom);

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            if (response && response.data && response.data.list) {
                response.data.list.forEach((item, index) => {
                    xAxisData.push(item.date);
                    if (item.isForecast) {
                        seriesData1.push(null);
                        seriesData2.push(item.inflowCount);
                    } else {
                        seriesData2.push(null);
                        seriesData1.push(item.inflowCount);
                    }
                });
            }

            let seriesData1lastIndex = seriesData1.indexOf(null);
            let seriesData2lastIndex = seriesData2.lastIndexOf(null);
            if (seriesData1lastIndex >= 0 && seriesData2lastIndex >= 0) {
                seriesData2[seriesData2lastIndex] = seriesData1[seriesData1lastIndex - 1];
            }

            let option = {
                tooltip: {
                    trigger: 'axis'
                    // trigger: 'item'
                },
                legend: {
                    data: ['每日流入案件量', '流入案件量预测']
                },
                xAxis: {
                    type: 'category',
                    data: xAxisData
                },
                yAxis: {
                    type: 'value'
                },
                grid: {
                    left: '5%',
                    right: '5%'
                    // bottom: '20px'
                },
                color: ['#0346F8', '#3E6CF1'],
                dataZoom: [
                    {
                        type: 'slider',
                        height: '15px',
                        xAxisIndex: 0,
                        filterMode: 'none'
                    }
                ],
                series: [
                    {
                        name: '每日流入案件量',
                        type: 'line',
                        lineStyle: {
                            // type: 'dashed', // 这里将折线设置为虚线
                            shadowColor: '#0346F8',
                            shadowOffsetX: 0,
                            // 设置阴影沿y轴偏移量为9
                            shadowOffsetY: 0,
                            // 设置阴影的模糊大小
                            shadowBlur: 10
                        },
                        data: seriesData1
                    },
                    {
                        name: '流入案件量预测',
                        type: 'line',
                        lineStyle: {
                            type: 'dashed', // 这里将折线设置为虚线
                            shadowColor: '#0346F8',
                            shadowOffsetX: 0,
                            // 设置阴影沿y轴偏移量为9
                            shadowOffsetY: 0,
                            // 设置阴影的模糊大小
                            shadowBlur: 15
                        },
                        data: seriesData2
                    }
                ]
            };

            this.myChartId1.setOption(option);
        },
        setChart2(response) {
            let chartDom = document.getElementById('chartId2');
            if (!chartDom) {
                return;
            }
            this.myChartId2 = echarts.init(chartDom);

            let legendData = [];
            let xAxisData = [];
            let optionsDataAll = {
                seriesData1: [],
                seriesData2: [],
                seriesData3: [],
                seriesStr1: null,
                seriesStr2: null,
                seriesStr3: null
            };

            if (response.columns) {
                response.columns.forEach((item, index) => {
                    if (item.title && item.title.length >= 3) {
                        legendData.push(item.title);
                        optionsDataAll['seriesStr' + index] = item.code;
                    }
                });
            }

            if (response.data && response.data.list) {
                response.data.list.forEach((item, index) => {
                    xAxisData.push(item.date);
                    if (item[optionsDataAll.seriesStr1]) {
                        optionsDataAll.seriesData1.push(item[optionsDataAll.seriesStr1]);
                    }
                    if (item[optionsDataAll.seriesStr2]) {
                        optionsDataAll.seriesData2.push(item[optionsDataAll.seriesStr2]);
                    }
                    if (item[optionsDataAll.seriesStr3]) {
                        optionsDataAll.seriesData3.push(item[optionsDataAll.seriesStr3]);
                    }
                });
            }

            let seriesDataAll = [];
            let optionColor = ['#87ACFE', '#A2A2A3', '#A37CFF'];
            if (legendData && legendData.length > 0) {
                legendData.forEach((item, index) => {
                    seriesDataAll.push({
                        name: item,
                        type: 'line',
                        lineStyle: {
                            shadowColor: optionColor[index],
                            shadowOffsetX: 0,
                            // 设置阴影沿y轴偏移量为9
                            shadowOffsetY: 0,
                            // 设置阴影的模糊大小
                            shadowBlur: 10,
                            normal: {
                                width: 4 // 正常状态下的线条宽度
                            }
                        },
                        data: optionsDataAll['seriesData' + (index + 1)]
                    });
                });
            }

            let option = {
                tooltip: {
                    trigger: 'axis'
                    // trigger: 'item'
                },
                legend: {
                    data: legendData
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        show: true,
                        interval: 0, // 使x轴文字显示全
                        formatter: function (params) {
                            var newParamsName = '';
                            var paramsNameNumber = params.length;
                            var provideNumber = 5; // 一行显示几个字
                            var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 计算需要几行显示
                            for (var i = 0; i < rowNumber; i++) {
                                var tempStr = '';
                                for (var j = 0; j < provideNumber; j++) {
                                    var index = i * provideNumber + j;
                                    if (index < paramsNameNumber) {
                                        tempStr += params[index];
                                    }
                                }
                                newParamsName += tempStr + '\n'; // 换行
                            }
                            return newParamsName;
                        }
                    },
                    data: xAxisData
                },
                yAxis: {
                    type: 'value',
                    // name: '单位：%'
                    axisLabel: {
                        formatter: function (value, index) {
                            return value + '%'; // 这里将值后面添加百分号作为单位
                        }
                    }
                },
                grid: {
                    // left: '60px',
                    left: '5%',
                    right: '5%',
                    bottom: '3%',
                    //     top: '50px',
                    containLabel: true
                },
                color: optionColor,
                series: seriesDataAll
            };

            this.myChartId2.clear();
            this.myChartId2.setOption(option);
        },
        setChart3(response) {
            let chartDom = document.getElementById('chartId3');
            if (!chartDom) {
                return;
            }
            this.myChartId3 = echarts.init(chartDom);

            let legendData = [];
            let xAxisData = [];
            let optionsDataAll = {
                seriesData1: [],
                seriesData2: [],
                seriesStr1: null,
                seriesStr2: null
            };

            if (response.columns) {
                response.columns.forEach((item, index) => {
                    if (item.title && item.title.length >= 3) {
                        legendData.push(item.title);
                        optionsDataAll['seriesStr' + index] = item.code;
                    }
                });
            }

            if (response.data && response.data.list) {
                response.data.list.forEach((item, index) => {
                    xAxisData.push(item.date);
                    if (item[optionsDataAll.seriesStr1]) {
                        if (typeof item[optionsDataAll.seriesStr1] != 'number') {
                            optionsDataAll.seriesData1.push(Number(String(item[optionsDataAll.seriesStr1]).replace(/,/g, '')));
                        } else {
                            optionsDataAll.seriesData1.push(item[optionsDataAll.seriesStr1]);
                        }
                    }
                    if (item[optionsDataAll.seriesStr2]) {
                        if (typeof item[optionsDataAll.seriesStr1] != 'number') {
                            optionsDataAll.seriesData2.push(Number(String(item[optionsDataAll.seriesStr2]).replace(/,/g, '')));
                        } else {
                            optionsDataAll.seriesData2.push(item[optionsDataAll.seriesStr2]);
                        }
                    }
                });
            }

            let seriesDataAll = [];
            let optionColor = ['#2C7BE4', '#CC66FF'];
            if (legendData && legendData.length > 0) {
                legendData.forEach((item, index) => {
                    seriesDataAll.push({
                        name: item,
                        type: 'bar',
                        yAxisIndex: index,
                        barMaxWidth: '28px',
                        emphasis: {
                            focus: 'series'
                        },
                        data: optionsDataAll['seriesData' + (index + 1)]
                    });
                });
            }

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: legendData
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        axisLabel: {
                            show: true,
                            interval: 0, // 使x轴文字显示全
                            formatter: function (params) {
                                var newParamsName = '';
                                var paramsNameNumber = params.length;
                                var provideNumber = 5; // 一行显示几个字
                                var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 计算需要几行显示
                                for (var i = 0; i < rowNumber; i++) {
                                    var tempStr = '';
                                    for (var j = 0; j < provideNumber; j++) {
                                        var index = i * provideNumber + j;
                                        if (index < paramsNameNumber) {
                                            tempStr += params[index];
                                        }
                                    }
                                    newParamsName += tempStr + '\n'; // 换行
                                }
                                return newParamsName;
                            }
                        },
                        data: xAxisData
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '金额（元）'
                    },
                    {
                        type: 'value',
                        name: '案件量',
                        splitLine: {
                            show: false // 不显示Y轴网格线
                        }
                    }
                ],
                color: optionColor,
                series: seriesDataAll
            };

            this.myChartId3.setOption(option);
        },
        setRiskChart(response, index) {
            let tmpResponse = JSON.parse(JSON.stringify(response));
            let chartDom = document.getElementById('riskChart' + index);
            if (!chartDom) {
                return;
            }
            this['riskChart' + index] = echarts.init(chartDom);
            let tooltipObj = { trigger: 'item', show: false };
            let graphic = {};
            if (index == 3) {
                graphic = {
                    type: 'text',
                    left: response[2].data.length > 6 ? '37%' : response[2].data.length == 6 ? '40%' : '52%',
                    top: '53%',
                    style: {
                        text: response[2].data,
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fill: '#000',
                        fontSize: 18
                    }
                };
            } else {
                graphic = {
                    type: 'text',
                    left: response[2].data.length > 6 ? '37%' : response[2].data.length == 6 ? '39%' : '52%',
                    top: '52%',
                    style: {
                        text: response[2].data,
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fill: '#000',
                        fontSize: 18
                    }
                };
            }
            let value1 = 0;
            if (response[1].data > 0 && response[0].data > 0) {
                value1 = response[0].data - response[1].data;
            }
            let option = {
                graphic: graphic,
                tooltip: tooltipObj,
                series: [
                    {
                        type: 'pie',
                        radius: ['50%', '60%'],
                        center: ['55%', '55%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        color: ['#F7F7F7', '#B266FE'],
                        hoverAnimation: false,
                        data: [
                            { value: value1 || 0, name: index != 3 ? '作业案件量' : '验真失败案件量' },
                            { value: response[1].data || 0, name: index != 3 ? this.riskTypeTitleData[index] : '验真案件量' }
                        ]
                    }
                ]
            };

            this['riskChart' + index].clear();
            this['riskChart' + index].setOption(option);
        },
        getCaseDataStatistics() {
            this.loadingChart1 = true;
            this.instance.post('/tpa/api/dashboard/fixed/caseDataStatistics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.setOverviewChart1(data.data);
                    this.loadingChart1 = false;
                }
            });
        },
        getCaseDataStatistics2() {
            this.loadingChart2 = true;
            this.instance.post('/tpa/api/dashboard/fixed/caseDataStatistics2', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.setOverviewChart2(data.data);
                    this.loadingChart2 = false;
                }
            });
        },
        getCaseDataStatistics3() {
            this.loadingChart3 = true;
            this.instance.post('/tpa/api/dashboard/fixed/caseDataStatistics3', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.setOverviewChart3(data.data);
                    this.loadingChart3 = false;
                }
            });
        },
        getCaseDataStatistics4() {
            this.instance.post('/tpa/api/dashboard/fixed/preCaseStatistics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.setOverviewChart4(data.data);
                }
            });
        },
        getCaseDataStatistics5() {
            this.instance.post('/tpa/api/dashboard/fixed/preCaseStatistics2', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.setOverviewChart5(data.data);
                }
            });
        },
        getPreCaseTimeAnalysis() {
            this.instance.post('/tpa/api/dashboard/fixed/preCaseTimeAnalysis', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.preCaseTimeAnalysisData = data.data;
                }
            });
        },
        setOverviewChart1(response) {
            let chartDom = document.getElementById('overviewChart1');
            if (!chartDom) {
                return;
            }
            this.overviewChart1 = echarts.init(chartDom);

            let option = {
                title: {
                    show: true,
                    text: '案件总量', //要显示的文本
                    left: '22%',
                    top: '30%',
                    textStyle: {
                        color: '#161616',
                        fontWeight: 'bold',
                        fontSize: '14'
                    }
                },
                graphic: {
                    type: 'text',
                    left: '22%',
                    top: '40%',
                    style: {
                        text: response[0].data,
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fill: '#000',
                        fontSize: 20
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    data: ['已接案件量', '', '未接案件量'], // 这里是图例的默认显示内容
                    left: '0px',
                    bottom: '-5px',
                    formatter: function (name) {
                        let legendStr = '';
                        if (response) {
                            response.forEach((item, index) => {
                                if (item.name == name) {
                                    legendStr = `${item.name}：${item.data}  占比：${response[index + 1].data}`;
                                }
                            });
                        }
                        return legendStr;
                    }
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['40%', '50%'],
                        center: ['30%', '40%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        color: ['#15D187', '#B0EE68'],
                        data: [
                            { value: response[1].data || 0, name: response[1].name || '' },
                            { value: response[3].data || 0, name: response[3].name || '' }
                        ]
                    }
                ]
            };

            this.overviewChart1.setOption(option);
        },
        setOverviewChart2(response) {
            let chartDom = document.getElementById('overviewChart2');
            if (!chartDom) {
                return;
            }
            this.overviewChart2 = echarts.init(chartDom);

            let option = {
                title: {
                    show: true,
                    text: '已接案件', //要显示的文本
                    left: '22%',
                    top: '30%',
                    textStyle: {
                        color: '#161616',
                        fontWeight: 'bold',
                        fontSize: '14'
                    }
                },
                graphic: {
                    type: 'text',
                    left: '22%',
                    top: '40%',
                    style: {
                        text: response[0].data,
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fill: '#000',
                        fontSize: 20
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    data: ['已决案件', '', '未决案件'], // 这里是图例的默认显示内容
                    left: '0px',
                    bottom: '-5px',
                    formatter: function (name) {
                        let legendStr = '';
                        if (response) {
                            response.forEach((item, index) => {
                                if (item.name == name) {
                                    legendStr = `${item.name}：${item.data}  占比：${response[index + 1].data}`;
                                }
                            });
                        }
                        return legendStr;
                    }
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['40%', '50%'],
                        center: ['30%', '40%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        color: ['#15D187', '#B0EE68'],
                        data: [
                            { value: response[1].data || 0, name: response[1].name || '' },
                            { value: response[3].data || 0, name: response[3].name || '' }
                        ]
                    }
                ]
            };

            this.overviewChart2.setOption(option);
        },
        setOverviewChart3(response) {
            let chartDom = document.getElementById('overviewChart3');
            if (!chartDom) {
                return;
            }
            this.overviewChart3 = echarts.init(chartDom);

            let option = {
                title: {
                    show: true,
                    text: '未决案件', //要显示的文本
                    left: '32%',
                    top: '30%',
                    textStyle: {
                        color: '#161616',
                        fontWeight: 'bold',
                        fontSize: '14'
                    }
                },
                graphic: {
                    type: 'text',
                    left: '34%',
                    top: '40%',
                    style: {
                        text: response[0].data,
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fill: '#000',
                        fontSize: 20
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    data: ['待定损案件', '待查勘案件', '', '查勘中案件', '核损中案件', '', '待核赔案件'], // 这里是图例的默认显示内容
                    left: '0px',
                    bottom: '-5px',
                    formatter: function (name) {
                        let legendStr = '';
                        if (response) {
                            response.forEach((item, index) => {
                                if (item.name == name) {
                                    legendStr = `${item.name}：${item.data}  占比：${response[index + 1].data}`;
                                }
                            });
                        }
                        return legendStr;
                    }
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['40%', '50%'],
                        center: ['40%', '40%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        color: ['#F02423', '#5A61F8', '#FFC700', '#2C38FF', '#9A00FF'],
                        data: [
                            { value: response[1].data || 0, name: response[1].name || '' },
                            { value: response[3].data || 0, name: response[3].name || '' },
                            { value: response[5].data || 0, name: response[5].name || '' },
                            { value: response[7].data || 0, name: response[7].name || '' },
                            { value: response[9].data || 0, name: response[9].name || '' }
                        ]
                    }
                ]
            };

            this.overviewChart3.setOption(option);
        },
        setOverviewChart4(response) {
            let chartDom = document.getElementById('overviewChart4');
            if (!chartDom) {
                return;
            }
            this.overviewChart4 = echarts.init(chartDom);

            let option = {
                title: {
                    show: true,
                    text: '案件总量', //要显示的文本
                    left: '22%',
                    top: '30%',
                    textStyle: {
                        color: '#161616',
                        fontWeight: 'bold',
                        fontSize: '14'
                    }
                },
                graphic: {
                    type: 'text',
                    left: '24%',
                    top: '40%',
                    style: {
                        text: response[0].data,
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fill: '#000',
                        fontSize: 20
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    data: ['已接案件量', '', '未接案件量'], // 这里是图例的默认显示内容
                    left: '0px',
                    bottom: '-5px',
                    formatter: function (name) {
                        let legendStr = '';
                        if (response) {
                            response.forEach((item, index) => {
                                if (item.name == name) {
                                    legendStr = `${item.name}：${item.data}  占比：${response[index + 1].data}`;
                                }
                            });
                        }
                        return legendStr;
                    }
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['40%', '50%'],
                        center: ['30%', '40%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        color: ['#14D187', '#D0F5A5'],
                        data: [
                            { value: response[1].data || 0, name: response[1].name || '' },
                            { value: response[3].data || 0, name: response[3].name || '' }
                        ]
                    }
                ]
            };

            this.overviewChart4.setOption(option);
        },
        setOverviewChart5(response) {
            let chartDom = document.getElementById('overviewChart5');
            if (!chartDom) {
                return;
            }
            this.overviewChart5 = echarts.init(chartDom);

            let option = {
                title: {
                    show: true,
                    text: '已接案件', //要显示的文本
                    left: '22%',
                    top: '30%',
                    textStyle: {
                        color: '#161616',
                        fontWeight: 'bold',
                        fontSize: '14'
                    }
                },
                graphic: {
                    type: 'text',
                    left: '24%',
                    top: '40%',
                    style: {
                        text: response[0].data,
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fill: '#000',
                        fontSize: 20
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    data: ['预报案', '预定损', '已完成'], // 这里是图例的默认显示内容
                    left: '0px',
                    bottom: '-5px',
                    formatter: function (name) {
                        let legendStr = '';
                        if (response) {
                            response.forEach((item, index) => {
                                if (item.name == name) {
                                    legendStr = `${item.name}：${item.data}  占比：${response[index + 1].data}`;
                                }
                            });
                        }
                        return legendStr;
                    }
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['40%', '50%'],
                        center: ['30%', '40%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        color: ['#FF733F', '#D1F6A5', '#15D187'],
                        data: [
                            { value: response[1].data || 0, name: response[1].name || '' },
                            { value: response[3].data || 0, name: response[3].name || '' },
                            { value: response[5].data || 0, name: response[5].name || '' }
                        ]
                    }
                ]
            };

            this.overviewChart5.setOption(option);
        }
    },
    watch: {
        isCollapse: {
            handler(val) {
                this.chartsResize();
            },
            immediate: true
        }
    }
};
